/*
 * @Author: wxb
 * @Date: 2021-08-10 14:25:38
 * @LastEditTime: 2021-08-10 14:27:13
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\collect\index.js
 */
import request from '@/utils/request'
import qs from 'qs'

/**
 * @description: 收藏/取消收藏
 * @param {*} params
 * @return {*}
 */
export function collection(params) {
  return request({
    url: '/api/admin/myCollection/collection',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 我的收藏列表
 * @param {*} params
 * @return {*}
 */
export function queryCollection(params) {
  return request({
    url: '/api/admin/myCollection/queryCollection',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

<!--
 * @Author: wxb
 * @Date: 2021-07-27 11:04:41
 * @LastEditTime: 2021-08-10 22:55:34
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Document\preview.vue
-->
<template>
  <div class="containner">
    <headerNav></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文档预览</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="document">
        <div class="document-title">{{fileName}}</div>
        <div class="document-attri">
          <span>{{filesize}}</span>
          <span>|</span>
          <span>{{dtCreate.substring(0,10)}}</span>
          <span>|</span>
          <span class="file-follow"
                :class="{followed:action==='add' }"
                @click="handleCollect"> <svg class="icon"
                 aria-hidden="true">
              <use :xlink:href="action | formatIcon"></use>
            </svg>收藏</span>
        </div>
        <div class="document-msg"
             v-show="isneeddown">请下载查看文档信息</div>
        <div class="document-content">
          <div class="article">
            <div class="article-content"
                 :style="{maxHeight:articleHeight+'px'}"
                 ref="article"
                 v-loading="loading">
              <img v-show="isImg"
                   :src="previewImg">
              <video id="myVideo"
                     class="video-js"
                     v-show="!isImg">
                <!-- <source :src="previewVideo"
                        type="video/mp4"> -->
              </video>
            </div>
            <div class="article-tools">
              <el-pagination background
                             layout="prev, slot, next"
                             :total="total"
                             :current-page.sync="currentPage"
                             :page-size="1"
                             @current-change="handleCurrentChange">
                <div class="page-preview">
                  <div class="page-preview-content">
                    <el-input v-model="currentPage4input"
                              @change="handleChangeCurrpage"></el-input>
                    <div class="page-preview-split">/{{total}}</div>
                  </div>
                </div>
              </el-pagination>
            </div>
          </div>
          <div class="relate-content"
               v-show="false">
            <div class="relate-content-title">相关解决方案</div>
            <ul class="relate-list">
              <li>
                <el-link>xxxxxxxxxxxxxxxx解决方案</el-link>
              </li>
              <li>
                <el-link>xxxxxxxxxxxxxxxx解决方案</el-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import dialogviporlogin from '@/components/dialogviporlogin'
import { mapGetters } from 'vuex'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import { documentDetails, preview } from '@/api/document'
import { collection } from '@/api/collect'
export default {
  name: 'preview',
  components: {
    headerNav,
    footerNav,
    dialogviporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  data() {
    return {
      total: 1,
      currentPage4input: 1,
      currentPage: 1,
      articleHeight: 550,
      filekey: '',
      myPlayer: null,
      // true代表为img展示，false代表为video展示
      isImg: true,
      defpreviewimg: require('@/assets/document/default-preview.png'),
      previewImg: '',
      previewVideo: '',
      isneeddown: false,
      fileParams: {},
      fileName: '',
      filesize: '',
      dtCreate: '',
      loading: false,
      action: 'cancle'
    }
  },
  methods: {
    authCheck() {
      const authObj = authenticate('preview', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
      } else {
        // 根据msg值不同展示不同的提示信息
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    },
    // 初始化页面
    initPage() {
      // 调用接口根据fileKey获取得到文件的基本信息
      const params = {
        fileKey: this.filekey
      }
      documentDetails(params).then(res => {
        if (res.ok) {
          // 根据返回值判断使用调用图片接口还是调用视频接口
          this.fileName = res.fileName
          this.dtCreate = res.createTime
          this.filesize = res.fileSize
          this.action = res.action
          // 获取文件流参数
          this.fileParams = {
            fileKey: this.filekey,
            fileSuffix: res.fileSuffix,
            pageNo: 1,
            url: res.filePath
          }
          // doc/docx/ppt/pptx/pdf/jpg/png/jpeg只有以上8中类型的需要调用图片接口
          // mp4需要调用视频接口
          const fileType = res.fileSuffix.toLowerCase()
          this.currentPage4input = 1
          this.currentPage = 1
          if (fileType.indexOf('doc') !== -1 || fileType.indexOf('ppt') !== -1 || fileType.indexOf('pdf') !== -1 || fileType.indexOf('png') !== -1 || fileType.indexOf('jpg') !== -1 || fileType.indexOf('jpeg') !== -1) {
            // 调用图片接口
            this.total = res.pages || 1
            this.isImg = true
            this.getPreview()
          } else if (fileType.indexOf('mp4') !== -1) {
            //  判断文件大写,如果文件大于100m则提示下载查看
            const filesize = res.fileSize.toUpperCase()
            if (parseFloat(filesize) > 200 && filesize.indexOf('MB')) {
              this.previewImg = this.defpreviewimg
              this.isneeddown = true
              this.isImg = true
            } else {
              // 调用视频接口
              // this.getPreview()
              this.previewImg = this.defpreviewimg
              this.isneeddown = true
              this.isImg = true
              // this.isImg = false
            }
          } else {
            this.previewImg = this.defpreviewimg
            this.isneeddown = true
            this.isImg = true
          }
        }
      }).catch(err => { console.log(err) })
    },
    // 获取视频/图片
    getPreview() {
      this.loading = true
      preview(this.fileParams).then(res => {
        // this.previewVideo = res
        // this.initVideo()
        this.loading = false
        if (res.size !== 0) {
          // 构造一个blob对象来处理数据
          if (this.isImg) {
            this.previewImg = URL.createObjectURL(res)
            this.$refs.article.scrollTop = 0
          } else {
            this.previewVideo = URL.createObjectURL(res)
            // 视频插件初始化
            this.myPlayer = this.$video(myVideo, {
              // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
              controls: true,
              // 自动播放属性,muted:静音播放
              autoplay: false,
              // 建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
              preload: 'auto',
              // 设置视频播放器的显示宽度（以像素为单位）
              width: '1110px',
              // 设置视频播放器的显示高度（以像素为单位）
              height: '550px'
            })
            this.myPlayer.src({
              src: this.previewVideo,
              type: res.type// 在重新添加视频源的时候需要给新的type的值
            })
            this.myPlayer.load()
          }
        }
      }).catch(err => { console.log(err) })
    },
    // 输入框改变
    handleChangeCurrpage(val) {
      const num = parseInt(val)
      if (isNaN(num) || num <= 0) {
        this.currentPage = 1
        this.currentPage4input = 1
      } else if (num > this.total) {
        this.currentPage = this.total
        this.currentPage4input = this.total
      } else {
        this.currentPage = num
      }

      if (this.isImg) {
        this.fileParams.pageNo = this.currentPage
        this.getPreview()
      }
    },
    // 处理分页
    handleCurrentChange(val) {
      this.currentPage4input = val
      this.fileParams.pageNo = val
      this.getPreview()
    },
    // 收藏附件
    handleCollect() {
      if (!getToken()) {
        this.$refs.dialogviporlogin.handleShow('login')
        return false
      }
      const params = {
        collectionitemId: this.filekey,
        collectionType: 'document',
        collectionitemName: this.fileName
      }

      if (this.action === 'cancle') {
        params.action = 'add'
        this.handleCollectEvent(params)
      } else if (this.action === 'add') {
        params.action = 'cancle'
        this.$confirm('确认取消附件收藏?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleCollectEvent(params)
        })
      }
    },
    handleCollectEvent(params) {
      collection(params).then(res => {
        if (res.ok) {
          this.action = params.action
          this.$forceUpdate()
          if (params.action === 'add') {
            this.$message({
              message: '收藏成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '取消收藏成功',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    }
  },
  mounted() {
    const reqParams = this.$route.params
    this.filekey = reqParams.filekey

    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }

    window.onresize = () => {
      const Height = window.innerHeight || document.documentElement.clientWidth || document.body.clientHeight
      this.articleHeight = Height - 290
    }

    this.$nextTick(() => {
      const Height = window.innerHeight || document.documentElement.clientWidth || document.body.clientHeight
      this.articleHeight = Height - 290
    })

    // this.initVideo()
  },
  filters: {
    formatIcon(action) {
      if (action === 'add') {
        return '#icon-shoucang2'
      } else {
        return '#icon-shoucang1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.document {
  margin-bottom: 1rem;
  &-title {
    padding: 1.4rem 0 0;
    color: #333;
    line-height: 1.33333333;
    font-size: 3.6rem;
  }

  &-attri {
    color: #999;
    margin-top: 0.5rem;
    span {
      display: inline-block;
      padding-right: 0.5rem;
    }

    .file-follow {
      cursor: pointer;
      .icon {
        font-size: 14px;
      }

      &.followed {
        .icon {
          color: #ec2929;
        }
      }
    }
  }

  &-msg {
    padding: 5px 0;
    line-height: 1.625;
    font-size: 16px;
    color: #666;
  }

  &-content {
    display: flex;
    padding-top: 1.5rem;
    .article {
      position: relative;
      width: 111rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      padding-bottom: 5.5rem;
      min-height: 45rem;
      &-content {
        overflow: hidden;
        overflow-y: auto;

        img {
          width: 100%;
        }

        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 2px;
          background-color: rgb(150, 151, 151);
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #ededed;
          border-radius: 2px;
        }
      }

      &-tools {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 900;
        background: #f3f3f3;
        color: #c3c3c3;
        text-align: center;
        box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
        height: 5.5rem;
        display: flex;
        align-content: center;
        justify-content: center;
        .el-pagination {
          margin-top: 1rem;
        }

        .page-preview {
          display: inline-block;
          height: 25px;

          &-content {
            display: flex;
          }

          .el-input {
            width: 40px;
            height: 25px;
            /deep/ .el-input__inner {
              height: 25px;
              padding: 0;
            }
          }

          &-split {
            height: 25px;
            line-height: 25px;
            padding-left: 6px;
          }
        }
      }
    }

    .relate-content {
      flex: 1;
      padding-left: 20px;

      &-title {
        font-size: 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1.5;
        color: #333;
        padding-bottom: 10px;
      }

      .relate-list {
        li {
          padding: 5px 0;
        }
      }
    }
  }
}
</style>
